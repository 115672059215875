@media screen and (min-width: 768px) {
  .responsivepadding {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
}

@media screen and (max-width: 700px) {
  .responsivemargin {
    margin-top: 77px;
  }
}
@media screen and (min-width: 700px) {
  .responsivemargin {
    margin-top: 58px;
  }
}

.headerlist {
  display: flex;
  @media screen and (min-width: 768px) {
    justify-content: space-between;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .responsiveheadermargin {
    margin-bottom: 20px;
  }
}
// @media screen and (min-width: 768px) {
//   .responsiveheadermargin {
//     margin-bottom: 58px;
//   }
// }
