.tealcolor {
  color: #00b5ad !important;
}

.name {
  @extend .tealcolor;
  @media screen and (min-width: 600px) {
    font-size: 30px !important;
  }
  @media screen and (min-width: 400px) and (max-width: 599px) {
    font-size: 15px !important;
  }
  @media screen and (max-width: 399px) {
    font-size: 10px !important;
  }
}

.menu {
  border-radius: 0 !important;
}

.badge {
  position: absolute;
  top: 3px;
  right: 5px;
}
